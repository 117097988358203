<template>
  <div v-loading.fullscreen.lock="loading" class="elv-ledger-trail-balance-detail-page">
    <Header
      ref="headerRef"
      type="GENERAL_LEDGER"
      :title="t('menus.ledger')"
      :updatedOn="entityDetail?.progress.postingDoneTime ?? ''"
      :showRegenerateButton="false"
      :showRegenerate="false"
    />

    <HeaderTabs />

    <GeneralLedgerScreening
      ref="screeningRef"
      tableType="generalLedger"
      :defaultTime="defaultTime"
      :exportParams="exportParams"
      :defaultListType="defaultListType"
      @onChangeTime="onChangeTime"
      @onCheckTableExpand="onCheckTableExpand"
    />
    <ExportDataTaskHistoryInfo :exportType="exportProgressStatusType.trialBalance" />
    <ProcessTable
      ref="processTableRef"
      type="general-ledger"
      :height="tableHeight"
      :summaryParams="summaryParams"
      :dateTimeValue="dateTimeValue"
    />
  </div>
</template>
<script lang="ts" setup>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useI18n } from 'vue-i18n'
import { emitter } from '@/lib/mitt'
import Header from '../../components/Header.vue'
import HeaderTabs from '../components/HeaderTabs.vue'
import { useEntityStore } from '@/stores/modules/entity'
import ProcessTable from '../components/ProcessTable.vue'
import { exportProgressStatusType } from '@/config/global'
import { useLedgerStore } from '@/stores/modules/ledger/index'
import GeneralLedgerScreening from '../components/LedgerScreening.vue'
import { useLedgerTableConfigStore } from '@/stores/modules/ledger/tableConfig'
import ExportDataTaskHistoryInfo from '../../components/ExportDataTaskHistoryInfo.vue'

dayjs.extend(utc)
const { t } = useI18n()
const route = useRoute()
const ledgerStore = useLedgerStore()
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)
const tableConfigStore = useLedgerTableConfigStore()
const loading = ref(false)
const processTableRef = useTemplateRef('processTableRef')
const headerRef = useTemplateRef<InstanceType<typeof Header>>('headerRef')
const screeningRef = useTemplateRef<InstanceType<typeof GeneralLedgerScreening>>('screeningRef')
const defaultListType = ref('COLLAPSE')
const defaultTime = ref<string[]>(['', ''])

const dateTimeValue = computed(() => {
  return screeningRef.value?.dateTimeValue ?? []
})

const exportParams = computed(() => {
  return {
    type: exportProgressStatusType.trialBalance.value,
    extra: {
      periodBegin: dateTimeValue.value?.[0],
      periodEnd: dateTimeValue.value?.[1]
    }
  }
})

const summaryParams = computed(() => {
  return {
    periodBegin: dateTimeValue.value?.[0],
    periodEnd: dateTimeValue.value?.[1]
  }
})
const tableHeight = computed(() => {
  return headerRef.value?.isShowRegeneratedInfo ? `calc(100vh - 214px)` : `calc(100vh - 238px)`
})

const onCheckTableExpand = (status: string) => {
  defaultListType.value = status
  if (status === 'EXPAND') {
    ledgerStore.agGridApi?.expandAll()
  } else {
    ledgerStore.agGridApi?.collapseAll()
  }
}

const onChangeTime = () => {
  processTableRef.value?.resetList()
}

watch(
  [() => route, () => entityStore.entityDetail],
  async (newVal, oldVal) => {
    if (
      route.name === 'reports-general-ledger' &&
      newVal[1].websocketUrl &&
      newVal[1].websocketUrl !== oldVal?.[1]?.websocketUrl
    ) {
      loading.value = true
      const start = dayjs().tz(entityStore.entityDetail?.timezone).subtract(6, 'month').format('YYYY-MM-DD')
      const end = entityStore.entityDetail?.progress?.postingDoneTime
        ? dayjs
            .tz(entityStore.entityDetail?.progress?.postingDoneTime, entityStore.entityDetail?.timezone)
            .subtract(1, 'day')
            .format('YYYY-MM-DD')
        : dayjs().tz(entityStore.entityDetail?.timezone).subtract(1, 'day').format('YYYY-MM-DD')
      defaultTime.value = [start, end]
      tableConfigStore.processTableConfig = []
      await tableConfigStore.getProcessTableConfig('generalLedger')
      emitter.emit('resetList')
    }
    loading.value = false
  },
  { immediate: true, deep: true }
)
</script>
<style lang="scss" scoped>
.elv-ledger-trail-balance-detail-page {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
</style>
